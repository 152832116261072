<template>
    <b-container fluid>
        <!-- User Interface controls -->
        <b-row class="my-2">
<!--            <b-col cols="4" class=" text-left">-->
<!--                <b-button size="md" v-b-modal="infoModal.id">新增</b-button>-->
<!--            </b-col>-->

            <!--            <b-col cols="8" class="my-1">-->
            <!--                <b-form-group-->
            <!--                    label="Per page"-->
            <!--                    label-cols-sm="6"-->
            <!--                    label-cols-md="4"-->
            <!--                    label-cols-lg="3"-->
            <!--                    label-align-sm="right"-->
            <!--                    label-size="sm"-->
            <!--                    label-for="perPageSelect"-->
            <!--                    class="mb-0"-->
            <!--                >-->
            <!--                    <b-form-select-->
            <!--                        v-model="perPage"-->
            <!--                        id="perPageSelect"-->
            <!--                        size="sm"-->
            <!--                        :options="pageOptions"-->
            <!--                    ></b-form-select>-->
            <!--                </b-form-group>-->
            <!--            </b-col>-->
        </b-row>

        <!-- Main table element -->
        <b-table
            show-empty
            small
            stacked="md"
            :items="images"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            hover
        >
            <template #cell(src)="row">
                <b-img-lazy center :src="`${testUrl}${row.value}`" alt="Center image" class="sukudai-images"/>
            </template>

            <template #cell(class)="row">
                {{ row.value }}
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                </b-card>
            </template>

            <template #cell(actions)="row">
                <b-button size="md" @click="info(row.item, row.index, $event.target)" class="mr-1">更新</b-button>
                <b-button size="md" @click="toggleActive(row.item)">啟/停用</b-button>
            </template>
        </b-table>

        <b-row class="justify-content-center">
            <b-col cols="7" md="6" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>

        <!-- Info modal -->
        <b-modal :id="infoModal.id" :title="infoModal.title ? '編輯圖片' : '新增圖片'" ok-only @hide="resetInfoModal">
            <b-form enctype="multipart/form-data" id="upload-image" @submit="updateImage">
                <b-col cols="12" v-if="infoModal.content.src">
                    <b-img :src="`${testUrl}${infoModal.content.src}`" style="width: 100%"/>
                </b-col>

                <b-col cols="12" class="my-2">
<!--                    <b-form-group id="input-group-company_categ">-->
<!--                        <b-form-select name="k" required :options="categs" v-model="infoModal.categ">-->
<!--                        </b-form-select>-->
<!--                    </b-form-group>-->

                    <b-form-group id="input-group-company_tel">
                        <b-file
                            name="image"
                            accept=".png, .jpg, .gif, .bmp"
                            placeholder="請選擇圖片..." required/>
                    </b-form-group>

                    <b-button type="submit" v-text="infoModal.title ? '更新' : '上傳'"/>
                </b-col>
            </b-form>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            // TODO 這裡要綁成產品時要移除
            // testUrl: 'http://34.81.255.46',
            testUrl: '',

            images: [],
            categs: [
                {value: '', text:'請選擇類別'},
                {value: 'Home', text:'首頁'},
                {value: 'AboutUs', text:'關於我們'},
                {value: 'AboutTeam', text:'專業團隊'},
                {value: 'Features', text:'服務特色'},
            ],
            fields: [
                {key: 'src', label: '圖片', thClass: 'w-50'},
                {
                    key: 'class',
                    label: '類別', sortable: true, sortDirection: 'desc',
                    thClass: 'w-25',
                    tdClass: 'py-5',
                    formatter: this.imgClassName
                },
                // {
                //     key: 'isActive',
                //     label: '啟用',
                //     tdClass: 'py-5',
                //     formatter: (value) => {
                //         return value === 't' ? '是' : '否'
                //     },
                //     sortable: true,
                //     sortByFormatted: true,
                //     filterByFormatted: true,
                //     thClass: 'w-25',
                // },
                {key: 'actions', label: '動作', thClass: 'w-25', tdClass: 'py-5',}
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
                categ: ''
            },
            ajaxModalId: 'ajax-msg',
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        }
    },
    created() {
        this.getImages();
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.images.length
    },

    methods: {
        info(item, index, button) {
            this.infoModal.title = `[${this.imgClassName(item.class)}] 第${index + 1}張圖片`;
            // this.infoModal.content = JSON.stringify(item, null, 2)
            this.infoModal.content = item;
            this.$root.$emit('bv::show::modal', this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        imgClassName(value) {
            return {
                'Home': '首頁',
                'AboutUs': '關於我們',
                'AboutTeam': '專業團隊',
                'Features': '服務特色',
            }[value ?? ''] ?? '未知的類別';
        },

        async updateImage(event) {
            event.preventDefault();
            let formData = new FormData(document.querySelector('#upload-image'));
            // formData.append('k', this.infoModal.content.src.replace('/imgSrc/', ''));
            formData.append('k', this.infoModal.content.src.replace('/api/img.php?k=', ''));
            formData.append('username', sessionStorage.getItem('account', ''));
            formData.append('token', sessionStorage.getItem('token', ''));

            let response = await this.axios({
                method: 'POST',
                url: `${this.GLOBAL.serverSrc}images.php`,
                data: formData,
                mimeType: 'multipart/form-data',
            });

            if (response.data.status === '1') {
                console.log('更新完成');
                let src = response.data.path;
                this.images.forEach((item, index) => {
                    if (item.src === src) {
                        console.log(this.images[index], src);
                        this.images[index].src = '';
                        this.images[index].src = src;
                    }
                });
            }
            console.log(response.data);

            this.$root.$emit('bv::hide::modal', this.infoModal.id);
        },

        async toggleActive(item) {
            let formData = new FormData();
            formData.append('k', item.src.replace('/api/img.php?k=', ''));
            formData.append('username', sessionStorage.getItem('account', ''));
            formData.append('token', sessionStorage.getItem('token', ''));
            formData.append('toggle_active', 'true');

            let response = await this.axios({
                method: 'POST',
                url: `${this.GLOBAL.serverSrc}images.php`,
                data: formData,
                mimeType: 'multipart/form-data',
            });

            if (response.data.status === '1') {
                console.log('更新完成');
            }
        },

        async getImages() {
            let response = await this.axios.get(`${this.GLOBAL.serverSrc}images.php?page=All`);
            let data = response.data;
            if (data.status) {
                let defaultSort = [];
                let names = [
                    'Home',
                    'AboutUs',
                    'AboutTeam',
                    'Features',
                ]
                for (let i=0; i<names.length; i++) {
                    data.data.filter(l => l.class === names[i]).forEach(l => defaultSort.push(l));
                }

                this.images = defaultSort;
                this.totalRows = data.data.length;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sukudai-images {
    object-fit: cover;
    width: 100%;
    height: 150px;
}
</style>